import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

export function OrderDialogStage({children, title, hideOrderDialog}) {
  return <article style={{
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    display: 'fixed',
    zIndex: 999,
  }}>
    <header>
      <a
        onClick={hideOrderDialog}
        aria-label="Close"
        className="close"
        style={{cursor: 'pointer', float: 'right'}}>
        <FontAwesomeIcon icon={faXmark}/>
      </a>
      {title}
    </header>
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '2rem',
      justifyContent: 'center',
    }}>
      {children}
    </div>
  </article>
}
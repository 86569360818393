import './App.css';
import {useState} from "react";
import {Title} from "./Title";
import {CreateUser} from "./CreateUser";
import {getDaysUntilMettwoch} from "./getDaysUntilMettwoch";
import {useMettwoch} from "./useMettwoch";
import {Mettwoch} from "./Mettwoch";

function App() {
  const {mettwoch, setMettwoch, error, isLoading} = useMettwoch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const daysUntilMettwoch = getDaysUntilMettwoch();
  const showApp = daysUntilMettwoch === 0 && user && mettwoch && !isLoading;

  return (
    <div className={'container-fluid'} style={{textAlign: 'center'}}>
      <Title user={user}/>
      {showApp && <Mettwoch user={user}/>}
      {!user && <CreateUser setUser={setUser}/>}
      {isLoading && <article aria-busy="true"/>}
    </div>
  );
}

export default App;

import {useMettwoch} from "./useMettwoch";
import {OrderDialogStage} from "./OrderDialogStage";

export function OrderDialog({
                              finishOrderDialog,
                              updateNewOrder,
                              hideOrderDialog,
                              orderDialogStage,
                              setOrderDialogStage
                            }) {
  const {mettwoch, setMettwoch, isLoading} = useMettwoch();

  if (orderDialogStage === "BROETCHEN") {
    return <OrderDialogStage title={'Welches Brötchen?'} hideOrderDialog={hideOrderDialog}>
      {mettwoch.broetchenTypes.map(broetchen =>
        <button
          onClick={() => {
            finishOrderDialog({broetchen});
          }}
          key={broetchen.broetchenTypeId}
          style={{width: '12rem'}}
        >
          <img src={`/img/broetchen/${broetchen.shortName}.webp`} style={{width: '100%', display: 'block'}}/>
          {broetchen.name}
        </button>)}
    </OrderDialogStage>;
  }

}
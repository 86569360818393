import useSWR from 'swr'

export function useMettwoch() {
  const fetcher = (...args) => fetch(args).then(res => res.json());
  const minutes = 60 * 1000;
  const seconds = 1000;
  const stateUrl = 'https://mettwoch.mudkips.de/api/state/';
  const {data, mutate, error, isLoading} = useSWR(
    stateUrl,
    fetcher,
    {
      dedupingInterval: 2 * seconds,
      refreshInterval: 5 * seconds, // fixme
    }
  );

  return {
    mettwoch: data,
    setMettwoch: mutate,
    isLoading,
    error,
  };
}
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faScissors, faClone, faTrash} from '@fortawesome/free-solid-svg-icons'

export function Orders({orders, addOrder, removeOrder, startOrderDialog}) {

  const cardStyle = {margin: 0, width: '20rem'};
  const cards = orders.map(order =>
    <article style={cardStyle} key={order.id}>
      <img src={`/img/broetchen/${order.broetchen.shortName}.webp`} style={{opacity: order.loading ? 0.5 : 1}}/>
      <div>
        {order.broetchen.shortName} mit Mett
      </div>
      <div style={{
        marginTop: '1rem',
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <button
          className={'outline'}
          style={{width: 'auto', margin: 0}}
          data-tooltip={'Aufschneiden'}
          data-placement={'bottom'}>
          <FontAwesomeIcon icon={faScissors} flip="horizontal"/>
        </button>
        <button
          onClick={() => addOrder(order)}
          className={'outline'}
          style={{width: 'auto', margin: 0}}
          data-tooltip={'Noch so eins'}
          data-placement={'bottom'}>
          <FontAwesomeIcon icon={faClone}/>
        </button>
        <button
          onClick={() => removeOrder(order.id)}
          className={'outline'}
          style={{width: 'auto', margin: 0}}
          data-tooltip={'Weg damit'}
          data-placement={'bottom'}>
          <FontAwesomeIcon icon={faTrash}/>
        </button>
      </div>
    </article>
  );

  return <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2rem',
    justifyContent: 'center',
  }}>
    {cards}
    <article style={cardStyle}>
      <button onClick={startOrderDialog} className={'outline'} style={{aspectRatio: 1, fontSize: '600%', margin: 0}}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>
    </article>
  </div>
}
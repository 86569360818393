import {useState} from "react";

export function CreateUser({setUser}) {
  const [newUserName, setNewUserName] = useState('');

  const createUser = event => {
    fetch('https://mettwoch.mudkips.de/api/user/', {
      method: 'PUT',
      body: JSON.stringify({newUserName})
    }).then(re => re.json()).then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    });

    event.preventDefault();
  };

  return <form onSubmit={createUser} style={{margin: 'auto', maxWidth: '20rem'}}>
    <label htmlFor={'name'}>Wie ist dein Name?</label>
    <input type={'text'}
      id={'name'}
      value={newUserName}
      placeholder={'Sonic der Mettigel'}
      onChange={event => setNewUserName(event.target.value)}
      required/>
    <button type={'submit'}>OK</button>
  </form>;
}


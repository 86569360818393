import {useMettwoch} from "./useMettwoch";
import {useState} from "react";
import {OrderDialog} from "./OrderDialog";
import {Orders} from "./Orders";

export function Mettwoch({user}) {
  const {mettwoch, setMettwoch, isLoading} = useMettwoch();
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({});

  console.log('mettwoch', mettwoch);
  console.log('orders', mettwoch.orders);

  const [orderDialogState, setOrderDialogStage] = useState();
  const [orderDialogVisible, setOrderDialogVisible] = useState(false);
  const showOrderDialog = () => setOrderDialogVisible(true);
  const hideOrderDialog = () => setOrderDialogVisible(false);

  const addOrder = order => {
    fetch('https://mettwoch.mudkips.de/api/order/', {
      method: 'PUT',
      body: JSON.stringify({
        ...order,
        token: user.token,
      }),
    });

    const newMettwoch = {...mettwoch, orders: [...mettwoch.orders, {...order, loading: true}]};
    setMettwoch(newMettwoch, {revalidate:false});
  }

  const removeOrder = orderId => {
    setOrders(orders => [...orders].filter(order => order.id !== orderId))
  }

  const updateNewOrder = updatedNewOrder => {
    setNewOrder(newOrder => {
      return {...newOrder, updatedNewOrder};
    });
  }

  const startOrderDialog = () => {
    setNewOrder({orderId: crypto.randomUUID()});
    setOrderDialogStage("BROETCHEN");
    showOrderDialog();
  }

  const finishOrderDialog = orderUpdate => {
    const finalNewOrder = {...newOrder, ...orderUpdate};
    addOrder(finalNewOrder);
    hideOrderDialog();
  }

  return <>
    <Orders orders={mettwoch.orders} addOrder={addOrder} removeOrder={removeOrder} startOrderDialog={startOrderDialog}/>

    {orderDialogVisible &&
      <OrderDialog
        finishOrderDialog={finishOrderDialog}
        updateNewOrder={updateNewOrder}
        hideOrderDialog={hideOrderDialog}
        orderDialogStage={orderDialogState}
        setOrderDialogStage={setOrderDialogStage}
      />
    }
  </>;
}
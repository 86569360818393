import {getDaysUntilMettwoch} from "./getDaysUntilMettwoch";

export function Title({user}) {
  const daysUntilMettwoch = getDaysUntilMettwoch();

  const hallo = user && user.name ? `Hallo ${user.name}` : "Hallo";
  const mettwochOrNotMettwoch = daysUntilMettwoch > 0 ? 'nicht Mettwoch' : 'Mettwoch';

  return <div style={{margin: '1.5rem', marginBottom: '3rem'}}>
    <div style={{fontSize: '1.2rem', lineHeight: '120%'}}>{hallo}. Es ist</div>
    <div style={{
      fontSize: '2rem',
      lineHeight: '120%',
      fontWeight: 900
    }}>
      {mettwochOrNotMettwoch}.
    </div>
    {daysUntilMettwoch > 0 &&
      <div style={{marginTop: '2rem'}}>
        Aber keine Panik.<br/>
        Nur noch {daysUntilMettwoch} mal Schlafen.<br/>
        Dann ist wieder Mettwoch.
      </div>
    }
  </div>;
}